// $primary: #a51616;
$primary: #c92025; /* MAIN COLOR */
$secondary: #cc6265; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

* {
    margin: 0;
    padding: 0;
}
body {
    font-family: 'Catamaran', sans-serif;
    overflow-y: scroll;
    overflow-x: hidden;
}
p {
    
    font-size: 14px;
}

a {color: #fff;}


.flash {
	display:none;
}

h1 {font-family: 'Pacifico', cursive;
	color: #fff;


}


.container-fluid.noPad {margin-top: 0px;
				  padding-right: 0px;
				  padding-left: 0px;
          margin: 0;
                  background: white;
                }

.container-fluid.noPad .row, .container-fluid.noPad .col-md-4 {
    margin: 0px;
    padding: 0;
}

@media (min-width: 1199px){
    .container {
                padding: 0px;
                }
  }

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 350px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}



/* =========== Navigation ============ */
.navbar-brand {margin-top: 0px;
			   padding-top: 0px;
			   min-height: 107px;}

.navbar-right {margin-top: 40px;
              @media (max-width: 767px) {
                  margin-top: 0;

              }

              }

.nav > li > a {
@media (max-width: 480px) and (orientation:landscape) {
    padding: 5px 5px;

}
}

.navbar-default .navbar-nav > li > a { font-size: 1.5em;

}

.navbar-default .navbar-nav > li > a:hover { color: #c92025;

}

.navbar {
    margin-bottom: 0;
}
.navbar.navbar-default {
    z-index: 1000;
}

@media (max-width: 767px) {
.sticky-wrapper {
  height: 107px;
}
}
/* =========== Header ============ */
.header {
    background-image: url("../img/main_banner.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;   
    @media (max-width: 767px) {
      background-position: 35% 0%;
    }
    @media (max-width: 1024px) {
        background-attachment: scroll;
    }
}
.header-overlay {
    background-color: rgba(31, 31, 31, 0.75);
    padding: 200px 0px;

    @media (max-width: 767px) {

      padding: 100px 0px;
    }

}

.logo {padding-left: 15px;
	   padding-bottom: 25px;
       margin-top: 10px;
  
  @media (max-width: 767px) {
      padding-bottom: 0px;
      padding-left: 5px;
      width: 100%;
      max-width: 150px;

  }
}

.logo-large {
             display: block;
             margin: 0 auto; 

@media (max-width: 767px){

.logo-large {width: 100%;
             display: block;
             margin: 0 auto;

  }
} 
}
.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    
    background: transparent;
    border-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 3px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
}

.btn-download {margin-bottom: 15px !important; display: block; margin: 0 auto; max-width: 250px;}
.btn-download:hover {
    background-color: #fff;
    color: #c92025;

}
.btn-tour,
.btn-tour:focus,
.btn-tour:active,
.btn-tour:visited {
    color:$primary;
    margin-left: 20px;
    text-transform: uppercase;
    font-size: 19px;
    font-weight: 500;
    background: transparent;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
    
}
.btn-tour:hover {
    color: #fff;
}
.btn-tour i {
    font-size: 20px;
    padding-left: 5px;
    color: #fff;
}
hr {
  max-width: 100px;
  display: block;
  margin: 0 auto;
}
/* ============ MiniForm Area ============ */

.miniform {
	padding-top: 50px;
    padding-bottom: 50px;
	background-image: url('../img/section-bg.png');
    background-repeat: repeat-x;
    background-size: cover;
        
       @media (max-width: 767px){
            padding: 50px;

        }
        @media (max-width: 500px) {
          padding: 0;
        }

	}

.miniform h1 {
    position: relative;
    color: #fff;
    font-size: 4em;
    font-weight: bold;
    
      @media (max-width: 767px) {
            text-align: center;
            font-size: 2em;
      }

      @media (max-width: 991px) {
            text-align: center;
      }


}



.miniform p {
    color: #fff;
    font-size: 18px;
    line-height: 45px;
    font-weight: 300;
    text-align: left;
    margin-top: 45px;

   @media (max-width: 767px) {
        
        text-align: center;


    }
}

.miniform a {
  margin-left: 35%;
  margin-right: 35%;

      @media (max-width: 991px) {
      display: block;
      margin: 0 auto;
      max-width: 250px;

    }

} 

/*============== Features ==================== */


#box {width:100%;
      height:500px;
      box-shadow:inset 1px 1px 40px 0 rgba(0,0,0,.45);
      margin:0 auto 0 auto; 
      background:url(../img/features_1.jpg);
      background-size:cover;
      overflow:hidden;
      position: relative;
                     @media (max-width: 1199px){
                      background-position: 70%;
                  }
  
                  @media (max-width: 991px) {
                      background-position: 80% 20%;

                  }
}

#icon {font-size: 95px;
      color: #fff;
      transition: all 1s;
      -webkit-transition: all 1s;
      -o-transition: all 1s;
      -moz-transition: all 1s;
       position: absolute;
       opacity:0;
      top: 30%;
       left: 0;
       right: 0;
       bottom: 0;
       margin: auto;

       @media (max-width: 1024px) {
          opacity: 1;

       }


}

  #overlay    {background:rgba(0,0,0,.75);
               text-align:center;
               padding:0px;
               width: 100%;
               height: 100%;
               opacity:0;
               -webkit-transition: opacity .25s ease;
               -moz-transition: opacity .25s ease;

              @media (max-width: 1024px){
                    opacity: 1;
                  
                  }
            }
 
  #box:hover #overlay {
               opacity:1;}


#box:hover #icon {opacity: 1;}



#box2    {  width:100%;
           height:500px;
           box-shadow:inset 1px 1px 40px 0 rgba(0,0,0,.45);
           margin:0 auto 0 auto; 
           background:url(../img/features_2.jpg);
           background-size:cover;
           overflow:hidden;
           position: relative;
               @media (max-width: 1199px){
                      background-position: 90%;
                  }
              @media (max-width: 991px) {
                      background-position: 80% 20%;

                  }
         }

#icon2 {    font-size: 95px;
    color: #fff;
    transition: all 1s;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    -moz-transition: all 1s;
    position: absolute;
    opacity:0;
      top: 30%;
left: 0;
right: 0;
bottom: 0;
margin: auto;

       @media (max-width: 1024px) {
          opacity: 1;

       }

}

  #overlay2    {  background:rgba(0,0,0,.75);
               text-align:center;
               padding:0px;
               width: 100%;
               height: 100%;
               opacity:0;
               -webkit-transition: opacity .25s ease;
              -moz-transition: opacity .25s ease;

                @media (max-width: 1024px){
                      opacity: 1;
                  
              }
            }
 
  #box2:hover #overlay2 {
               opacity:1;}

#box2:hover #icon2 {opacity: 1;}


#box3    {width:100%;
          height:500px;
          box-shadow:inset 1px 1px 40px 0 rgba(0,0,0,.45);
          margin:0 auto 0 auto; 
          background:url(../img/features_3.jpg);
          background-size:cover;
          overflow:hidden;
          position: relative;
               @media (max-width: 1199px){
                      background-position: 90%;
                  }
              @media (max-width: 991px) {
                      background-position: 80% 20%;

                  }
        }

#icon3 {    font-size: 95px;
    color: #fff;
    transition: all 1s;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    -moz-transition: all 1s;
    position: absolute;
    opacity:0;
    top: 30%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

           @media (max-width: 1024px) {
          opacity: 1;

       }
}

  #overlay3    {  background:rgba(0,0,0,.75);
               text-align:center;
               padding:0px;
               width: 100%;
               height: 100%;
               opacity:0;
               -webkit-transition: opacity .25s ease;
                -moz-transition: opacity .25s ease;
              @media (max-width: 1024px){
              opacity: 1;
            }
              }
 
  #box3:hover #overlay3 {
               opacity:1;}

#box3:hover #icon3 {opacity: 1;}

.title {
    @media (max-width: 767px) {
         font-size: .25em;
    }

}

/* ============= Recent Member Section ======== */

.member-recent { padding: 50px 0px;
                 background: white;
				 
}

.img-member { 
             
              @media (max-width:550px) {
                margin: 0 auto;
                display: block;

              }



}

.img-member2 { border: 3px solid #c92025;
        padding: 5px;
        border-radius: 50%;
             
              @media (max-width:550px) {
                margin: 0 auto;
                display: block;

              }



}



 .member-names h1 {color: #c92025;
                   padding-bottom: 10px;
                   text-align: center;}

.member-logo {padding-top: 50px;
                @media (max-width: 767px){
                  padding-right: 12%;
                  padding-left: 12%;
                  padding-bottom: 5%;
                }
                @media (max-width: 991px){
                  padding: 0px;
                  padding-bottom: 5%;
                }
}

@media (max-width:550px) {

    .member {width: 100%;}

}

/*============= Call to Action =============== */

.call-to-action {
  padding: 40px 0;
	background-image: url('../img/section-bg.png');
    background-repeat: repeat-x;
    background-size: cover;
    text-align: center;
}

.call-to-action h1 {
    position: relative;
    text-align: center;
    color: #fff;
    font-size: 5em;
    font-weight: bold;
    @media (max-width: 767px) {
      font-size: 3em;

    }
}



.call-to-action p {
    color: #fff;
    font-size: 18px;
    line-height: 45px;
    font-weight: 300;
    text-align: left;
    margin-top: 45px;
    padding-bottom: 45px;

    @media(max-width: 767px) {
        text-align: center;

    }
}

.bigLogo {
  margin: 0 auto;
  padding: 20px;
}

input#username, input#password {
 @media (max-width: 1024px) {
  font-size: 16px;
 }
}

.privacy-terms-bg {background-color: white; padding: 0 25px;}

.top-pad a {
  color: red;
}
.top-pad ol {
  margin-left: 20px;
}









































